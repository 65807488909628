import React, { useMemo } from 'react';
import {
  Content,
  ContentHeader,
  ItemCardGrid,
} from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

export const HomePage = () => {
  const identityApi = useApi(identityApiRef);

  //const currTime = useMemo(() => moment(), []);

  const name = useAsync(async () => {
    const profileInfo = await identityApi.getProfileInfo();
    return profileInfo.displayName;
  });

  const helloMessage = useMemo(() => {
    const nameSuffix = name.loading ? '' : `, ${name.value}`;

    const currHour = new Date().getHours()

    if (currHour < 12) {
      return `☀️ Good morning${nameSuffix}`;
    } else if (currHour < 18) {
      return `☀️ Good afternoon${nameSuffix}`;
    } else {
      return `🌅 Good evening${nameSuffix}`;
    }
  }, [name]);

  return (
    <Content>
      <ContentHeader title={helloMessage} />
      <ItemCardGrid>
      </ItemCardGrid>
    </Content>
  );
};