import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage-community/plugin-tech-radar';
  
import { DiscoveryApi, FetchApi, IdentityApi } from "@backstage/core-plugin-api";


  export class TechRadarClient implements TechRadarApi {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi: IdentityApi

    constructor({discoveryApi, fetchApi, identityApi }: {discoveryApi: DiscoveryApi, fetchApi: FetchApi, identityApi: IdentityApi}) {
      this.discoveryApi = discoveryApi;
      this.fetchApi = fetchApi;
      this.identityApi = identityApi;
  }
    
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
      // if needed id prop can be used to fetch the correct data
      const proxyBaseUri = `${await this.discoveryApi.getBaseUrl('proxy')}/tech-radar${id ?? ""}`;
      const { token: idToken } = await this.identityApi.getCredentials();

     const data = await fetch(proxyBaseUri, {
        method: 'GET',
        headers: {
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
      }).then(res => res.json());

      // For example, this converts the timeline dates into date objects
      return {
        ...data,
        entries: data.entries.map((entry:any) => ({
          ...entry,
          timeline: entry.timeline.map((timeline:any) => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }
